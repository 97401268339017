<p-dialog
  header=""
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '30%', height: '70vh' }"
>
  <div class="loading-spinner" *ngIf="isLoading">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
  <div class="gap-3 mb-3" *ngIf="!isLoading">
    <!-- Título del préstamo -->
    <div class="flex justify-content-between align-items-center">
      <h2 class="text-orange-600">Préstamo</h2>
      <div class="flex justify-content-between align-items-baseline">
        <span class="text-xl font-bold">U$D/</span>
        <span class="text-2xl font-bold">8,000</span>
      </div>
    </div>
    
    <!-- Estado de la solicitud -->
    <div class="progress-bar">
      <!-- Línea de fondo completa -->
      <div class="line-background">
        <div class="line-active" [style.width.%]="(currentStep / (steps.length - 1)) * 100"></div>
      </div>
    
      <!-- Pasos -->
      <div *ngFor="let item of steps; let i = index" class="step">
        <div class="circle-container">
          <!-- Círculo del paso -->
          <div
            class="circle"
            [ngClass]="{ 'active': i <= currentStep }"
          ></div>
        </div>
        <!-- Etiqueta del paso -->
        <div class="label">{{ item.label }}</div>
      </div>
    </div>
    
    <!-- Detalles del préstamo -->
    <div class="mt-4">
      <div class="flex flex-column gap-5">
        <div>
          <p class="text-orange-400">Número de Cuotas</p>
          <p>Diez</p>
        </div>

        <div>
          <p class="text-orange-400">Cobrar cada</p>
          <p>Fin de mes</p>
        </div>
      </div>

      <p class="text-orange-400">Número de cuenta interbancaria en dólares</p>
      <p>x-xxxxx-xxx-xx-xxxxxxxxx-xxxxxx</p>

      <p class="text-orange-400">Descripción del préstamo</p>
      <p>Quiero comprarme artículos en dólares</p>
    </div>

    <hr />
    <!-- Mensaje de administración -->
    <div class="mt-4">
      <p class="text-orange-400">Mensaje de administración</p>
      <p>
        Estudiaremos su caso, le avisaremos lo más pronto posible si es aprobado
        el préstamo
      </p>
    </div>

    <!-- Botón de aceptar -->
    <div class="flex justify-content-center mt-4">
      <button
        pButton
        type="button"
        label="ACEPTAR"
        class="p-button-raised p-button-orange"
        (click)="onButtonClick()"
      ></button>
    </div>
  </div>
</p-dialog>
