import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CurrencyService } from '../../../services/currency.service';
import { CompanyService } from '../../../services/company.service';
import { EmployeeService } from '../../../services/employee.service';
// import { PaymentSlipService } from '../../services/payment-slip.service';
import { forkJoin } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-detail-solicitude-loan',
  templateUrl: './detail-solicitude-loan.component.html',
  styleUrls: ['./detail-solicitude-loan.component.scss'],
  providers: [DialogService],
})
export class DetailSolicitudeLoanComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() dataToView: any | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();

  activeStep: number = 1;

  steps = [
    { label: 'Recibido', key: 0 },
    { label: 'En evaluación', key: 1 },
    { label: 'Aprobado', key: 2 }
  ];
  currentStep = 1;
  

  constructor(
    // private paymentSlipService: PaymentSlipService,
    private currencyService: CurrencyService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private cdr: ChangeDetectorRef
  ) {}

  isLoading: boolean = false;
  paymentSlipId: number = 0;
  selectedEmpresa: IComboBase | null = null;
  selectedColaborador: IComboBase | null = null;
  selectedMoneda: IComboBase | null = null;
  monto: string | null = null;
  issueDate: Date | null = null;
  today: Date = new Date();
  isPaid: boolean = false;
  selectedFileName: string | null = null;

  empresas: IComboBase[] = [];
  colaboradores: IComboBase[] = [];
  monedas: IComboBase[] = [];

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  ngOnChanges(): void {
    console.log('en el onchanges del detail');
    // this.isLoading = true;
    if (this.visible && this.dataToView) {
      this.paymentSlipId = this.dataToView.paymentSlipId;

    //   this.paymentSlipService.getPaymentSlipById(this.paymentSlipId).subscribe({
    //     next: (payment) => {
    //       const observables = {
    //         empresas: this.companyService.comboCompany(),
    //         monedas: this.currencyService.comboCurrency(),
    //         colaboradores: this.employeeService.comboEmployee(
    //           payment.companyId
    //         ),
    //       };

    //       forkJoin(observables).subscribe({
    //         next: ({ empresas, monedas, colaboradores }) => {
    //           this.empresas = empresas;
    //           this.monedas = monedas;
    //           this.colaboradores = colaboradores;

    //           this.selectedEmpresa =
    //             this.empresas.find(
    //               (e) => String(e.key) === String(payment.companyId)
    //             ) || null;

    //           this.selectedColaborador =
    //             this.colaboradores.find(
    //               (c) => String(c.key) === String(payment.employeeId)
    //             ) || null;

    //           this.selectedMoneda =
    //             this.monedas.find(
    //               (m) => String(m.key) === String(payment.currencyId)
    //             ) || null;

    //           this.monto = payment.paymentSlipAmount || null;

    //           this.issueDate = payment.issueDate
    //             ? this.parseCustomDate(payment.issueDate)
    //             : null;

    //           this.isPaid = !!payment.isPaid;

    //           this.selectedFileName = payment.paymentSlipUrl
    //             ? this.extractFileName(payment.paymentSlipUrl)
    //             : null;

    //           this.isLoading = false;
    //           this.cdr.detectChanges();
    //         },
    //         error: (err) => {
    //           this.isLoading = false;
    //           console.error('Error al cargar los datos necesarios:', err);
    //         },
    //       });
    //     },
    //     error: (err) => {
    //       console.error(
    //         'Error al obtener los detalles del recibo de pago:',
    //         err
    //       );
    //     },
    //   });
    } else {
      // Limpia los valores al cerrar
      this.paymentSlipId = 0;
      this.selectedEmpresa = null;
      this.selectedColaborador = null;
      this.selectedMoneda = null;
      this.monto = null;
      this.issueDate = null;
      this.isPaid = false;
      this.selectedFileName = null;
      this.empresas = [];
      this.colaboradores = [];
      this.monedas = [];
    }
  }

  parseCustomDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    const fullYear = year < 100 ? 2000 + year : year;
    return new Date(fullYear, month - 1, day);
  }

  extractFileName(fileUrl: string): string {
    const fullFileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    return fullFileName.split('-').slice(1).join('-');
  }

  onButtonClick() {
    this.closeDialog(); // Opcional: cerrar el modal después de ejecutar la acción
  }
}
