<div class="vacation-request-container p-fluid">
  <h2 class="title">Especifica el periodo</h2>

  <!-- Selección de Fechas -->
  <div class="calendar-row">
    <div class="calendar-item">
      <label for="startDate">Inicio de vacaciones</label>
      <p-calendar
        id="startDate"
        [(ngModel)]="startDate"
        [icon]="'pi pi-pencil'"
        [showIcon]="true"
        (ngModelChange)="onStartDateChange($event)"
        [maxDate]="maxStartDate"
        dateFormat="dd/mm/yy"
      ></p-calendar>
    </div>
    <div class="calendar-item">
      <label for="endDate">Fin de vacaciones</label>
      <p-calendar
        id="endDate"
        [(ngModel)]="endDate"
      [icon]="'pi pi-pencil'"
      [showIcon]="true"
      (ngModelChange)="onEndDateChange($event)"
      [minDate]="startDate"
      [maxDate]="maxEndDate"
      dateFormat="dd/mm/yy"
      ></p-calendar>
    </div>
  </div>

  <!-- Tipo de Vacaciones -->
  <div class="p-field-autocomplete">
    <label for="vacationType">Tipo de vacaciones</label>
    <p-autoComplete
      id="vacationType"
      [(ngModel)]="selectedVacationType"
      [suggestions]="filteredVacationTypes"
      (completeMethod)="filterVacationTypes($event)"
      (input)="onChangeInput($event)"
      [dropdown]="true"
      [forceSelection]="true"
      placeholder="Seleccione el tipo"
      (onSelect)="onSelect($event)"
      [style]="{ width: '100%' }"
      [field]="'text'"
    ></p-autoComplete>
  </div>

  <!-- Iconos de Vacaciones -->
  <div class="vacation-icons">
    <div
      class="vacation-option"
      [ngClass]="{
        active: selectedOptionKey === '1',
        inactive: selectedOptionKey !== '1'
      }"
    >
      <img src="/assets/svg/vacaciones.svg" alt="" />
      <div class="vacation-bottom">
        <span>
          Vacaciones por ley
          <i
            class="pi pi-info-circle"
            (click)="
              openTooltipModal(
                'Vacaciones por ley',
                'Acumulas 1.25 días por mes laborado'
              )
            "
          ></i>
        </span>
        <small [ngClass]="{ highlight: selectedOptionKey === '1' }">
          {{ calculateAccumulatedDays }} días acumulados
        </small>
      </div>
    </div>

    <div
      class="vacation-option"
      [ngClass]="{
        active: selectedOptionKey === '2',
        inactive: selectedOptionKey !== '2'
      }"
    >
      <img src="/assets/svg/reloj.svg" alt="" />
      <div class="vacation-bottom">
        <span>
          Bono vacacional
          <i
            class="pi pi-info-circle"
            (click)="
              openTooltipModal(
                'Bono vacacional',
                'Este bono es entregado anualmente según las políticas de la empresa'
              )
            "
          ></i>
        </span>
        <small [ngClass]="{ highlight: selectedOptionKey === '2' }">
          Por año laborado
        </small>
      </div>
    </div>
  </div>

  <!-- Calendario -->
  <div class="calendar-container">
    <p-calendar
    [(ngModel)]="selectedDates"
    selectionMode="range"
    [readonlyInput]="true"
    inline="true"
    [minDate]="minDate"
    [maxDate]="maxEndDate"
    (onSelect)="onRangeSelect()"
    />
  </div>

  <!-- Mensaje Opcional -->
  <div class="p-field">
    <label for="message">Escriba un mensaje (Opcional)</label>
    <div class="textarea-container">
      <textarea
        id="message"
        rows="5"
        [(ngModel)]="message"
        placeholder="Escriba su mensaje aquí..."
        maxlength="200"
      ></textarea>
      <small>200/{{ message.length || 0 }}</small>
    </div>
  </div>

  <!-- Confirmación -->
  <div class="confirmation">
    <p-checkbox
      [(ngModel)]="confirmationChecked"
      binary="true"
      inputId="confirmation"
      (ngModelChange)="validateForm()"
    />
    <label for="confirmation">
      He revisado detalladamente mi solicitud de vacaciones
    </label>
  </div>

  <!-- Botón de Enviar -->
  <div class="submit-button">
    <button
      pButton
      type="button"
      label="Solicitar"
      [disabled]="isDisabled"
      (click)="onRegister()"
    ></button>
  </div>
</div>
