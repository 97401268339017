<div class="modal-container">
    <p-progressSpinner
        [style]="{ width: '10rem', height: '10rem' }"
        strokeWidth="3"
        fill="var(--surface-ground)"
        animationDuration="1.5s"
    ></p-progressSpinner>
</div>


