import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { PaymentSlipsModule } from './payment-slips/payment-slips.module';
import { HomeModule } from './home/home.module';
import { SolicitudeModule } from './solicitude/solicitude.module';
import { VacationModule } from './vacation/vacation.module';

export const routes: Routes = [
  {
    path: '', // Redirige a la ruta base
    redirectTo: 'auth/login', // Redirige directamente a login bajo "auth"
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => HomeModule),
      },
      {
        path: 'vacation',
        loadChildren: () =>
          import('./vacation/vacation.module').then(
            (m) => VacationModule
          ),
      },
      {
        path: 'payment-slips',
        loadChildren: () =>
          import('./payment-slips/payment-slips.module').then(
            (m) => PaymentSlipsModule
          ),
      },
      {
        path: 'solicitude',
       loadChildren: () =>
         import('./solicitude/solicitude.module').then((m) => SolicitudeModule),
      },
    ],
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () =>
  //     import('./pages/dashboard/dashboard.module').then(
  //       (m) => m.DashboardModule
  //     ),
  // },

  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },

  {
    path: '**', // Redirige cualquier ruta no definida
    redirectTo: 'error/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
