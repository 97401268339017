<p-dialog
  header=""
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '30%', height: '30vh' }"
>
  <div class="content-container">
    <!-- Título -->
    <div class="flex justify-content-between align-items-center">
      <h2>Eliminar</h2>
    </div>

    <!-- Mensaje -->
    <div>
      <p>¿Seguro que deseas proceder con la eliminación del registro?</p>
    </div>

    <!-- Botones -->
    <div class="button-container">
      <button
        pButton
        type="button"
        class="confirm"
        label="Confirmar"
        (click)="onButtonConfirmedClick()"
      ></button>
      <button
        pButton
        type="button"
        class="cancel"
        label="Cancelar"
        (click)="onButtonCancelClick()"
      ></button>
    </div>
  </div>
</p-dialog>
