import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { VacationRequestDTO } from '../../interfaces/vacation.interface';

@Injectable({
  providedIn: 'root',
})
export class VacationService {
  private apiUrl = `${environment.apiUrl}/solicitudes`;

  constructor(private http: HttpClient) {}

  createVacation(body: FormData): Observable<VacationRequestDTO> {
    return this.http.post<VacationRequestDTO>(
      this.apiUrl + '/registerSolicitudeVacation',
      body
    );
  }
}
