<div class="header">
  <div class="actions">
    <!-- Combo selector -->
    <div class="combo-select">
      <select
        [ngModel]="comboSelected"
        (ngModelChange)="onComboChange($event)">
        <option value="" disabled [selected]="!comboSelected" >{{ comboPlaceholder }}</option>
        <option value="">Todos</option>
        <option *ngFor="let option of comboOptions" [ngValue]="option">
          {{ option.text }}
        </option>
      </select>
    </div>

    <!-- Barra de búsqueda -->
    <div class="search-bar">
      <i class="pi pi-search search-icon"></i>
      <input
        type="text"
        [placeholder]="searchPlaceholder"
        [ngModel]="searchQuery"
        (ngModelChange)="onSearchInput($event)" />
    </div>
  </div>
</div>
