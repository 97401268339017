import { NgModule } from '@angular/core';
import { PaymentSlipsRoutingModule } from './payment-slips-routing.module';
import { PaymentSlipsComponent } from './payment-slips.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DetailModalComponent } from './components/detail-modal/detail-modal.component';

@NgModule({
  declarations: [
    PaymentSlipsComponent,
    DetailModalComponent
  ],
  imports: [
    PaymentSlipsRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    CalendarModule,
    InputSwitchModule
    ],
  providers: [
    DialogService,
  ]
})
export class PaymentSlipsModule {}
