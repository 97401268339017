import { Component } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-reject',
  templateUrl: './modal-reject.component.html',
  styleUrls: ['./modal-reject.component.scss'],
})
export class ModalRejectComponent {
  text: string = '';
  title: string = '';
  icon: string = '';
  showButton: boolean = false;
  buttonText: string = 'Aceptar';
  buttonAction: (() => void) | null = null;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.text = this.config.data.text;
    this.title = this.config.data.title;
    this.icon = this.config.data.icon;
    this.showButton = this.config.data.showButton || false;
    this.buttonText = this.config.data.buttonText || 'Aceptar';
    this.buttonAction = this.config.data.buttonAction || null;
  }

  closeModal() {
    this.ref.close();
  }

  onButtonClick() {
    if (this.buttonAction) {
      this.buttonAction();
    }
    this.closeModal(); // Opcional: cerrar el modal después de ejecutar la acción
  }
}
