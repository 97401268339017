import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '../services/employee.service';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  permissions: string[] = []; // Almacenará los permisos del usuario
  currentDate: string = '';
  userData: any = null;
  fullname: string = '';

  menuOptions = [
    {
      label: 'Inicio',
      icon: 'pi pi-home',
      route: '/dashboard/home',
      permission: 'VIEW_INICIO_COLLABORATOR',
    },
    {
      label: 'Vacaciones',
      icon: 'pi pi-sun',
      route: '/dashboard/vacation',
      permission: 'VIEW_VACACIONES_COLLABORATOR',
    },
    {
      label: 'Boletas',
      icon: 'pi pi-file',
      route: '/dashboard/payment-slips',
      permission: 'VIEW_BOLETAS_COLLABORATOR',
    },
    {
      label: 'Ticket y Vales',
      icon: 'pi pi-ticket',
      route: '/dashboard/ticket',
      permission: 'VIEW_VACACIONES_COLLABORATOR',
    },
    {
      label: 'Solicitudes',
      icon: 'pi pi-clock',
      route: '/dashboard/solicitude',
      permission: 'VIEW_BOLETAS_COLLABORATOR',
    }
  ];

  filteredMenuOptions: any[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private employeeService: EmployeeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      this.userData = user;
      // console.log('Usuario actualizado: ', this.userData);
      if (this.userData) {
        this.getName();
      } else {
        console.log('employeeId no está definido');
      }
    });

    this.loadPermissions();
    this.filterMenuOptions();

    const date = new Date();

    // Array con los meses en español
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    // Construir la fecha en el formato deseado
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Formato: "Diciembre 18 del 2024"
    this.currentDate = `${month} ${day} del ${year}`;
  }

  loadPermissions(): void {
    const storedAccess = localStorage.getItem('access');
    if (storedAccess) {
      const parsedAccess = JSON.parse(storedAccess);
      this.permissions = parsedAccess.permissions || [];
    }
  }

  filterMenuOptions(): void {
    this.filteredMenuOptions = this.menuOptions.filter((option) =>
      this.permissions.includes(option.permission)
    );
  }

  getName(): void {
    if (!this.userData) {
      console.error('Error: userData no está definido');
      return;
    }

    console.log('Llamando a la API con userData:', this.userData);
    this.employeeService.getEmployeeById(this.userData.employeeId).subscribe(
      (employee) => {
        if (employee) {
          this.fullname =
            employee.names.split(' ')[0] +
            ' ' +
            employee.lastNames.split(' ')[0];
          console.log('Nombre completo obtenido:', this.fullname);
          // this.cdr.detectChanges(); // Forzar actualización del DOM
        } else {
          console.log('No se encontró el empleado');
        }
      },
      (error) => {
        console.error('Error al obtener el empleado:', error);
      }
    );
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}
