import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HomeRoutingModule } from "./home-routing.module";
import { SharedModule } from "../shared/shared.module";
import { HomeComponent } from "./home.component";
import { CalendarModule } from "primeng/calendar";

@NgModule({
	declarations: [
		HomeComponent
	],
	imports: [
		CommonModule,
		HomeRoutingModule,
		CalendarModule,
		SharedModule
	]
})
export class HomeModule {}
