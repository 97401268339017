import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterContentInit,
  ContentChildren,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { IComboBase } from '../../interfaces/base/base.interface';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent implements OnInit, AfterContentInit {
  @Input() tabs: IComboBase[] = [];
  @Output() tabSelected = new EventEmitter<string>();
  @Input() displayedColumns: { key: string; header: string }[] = [];
  @Input() actionButtons: {
    icon: string;
    color: string;
    tooltip: string;
    action: (row: any) => void;
  }[] = [];
  @Input() set dataSource(data: any[]) {
    this.dataSourceInstance.data = data;
  }

  @Input() isLoading: boolean = true;
  @Input() showPreviewCard: boolean = false;
  @Input() customTemplates: { [key: string]: TemplateRef<any> } = {};
  @Input() hasCheckbox: boolean = false;

  @Output() rowSelected = new EventEmitter<any>();
  @Input() selectedDocument: SafeResourceUrl | null = null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  columnKeys: string[] = [];
  dataSourceInstance = new MatTableDataSource<any>();
  selectedRow: any = null;
  isDocumentLoading: boolean = false;

  @ContentChildren(TemplateRef) templates!: QueryList<TemplateRef<any>>;

  ngOnInit(): void {
    this.columnKeys = this.displayedColumns.map((c) => c.key);
    if (this.actionButtons.length > 0) {
      this.columnKeys.push('acciones');
    }

    if (this.hasCheckbox) {
      this.columnKeys.unshift('select');
    }
  }

  ngAfterViewInit(): void {
    this.dataSourceInstance.paginator = this.paginator;
  }

  ngAfterContentInit(): void {
    this.templates.forEach((template) => {
      const columnKey = (
        template as any
      )?.elementRef?.nativeElement?.getAttribute?.('columnKey');
      if (columnKey) {
        this.customTemplates[columnKey] = template;
      }
    });
  }

  onActionClick(row: any, action: (row: any) => void): void {
    action(row);
  }

  onTabChange(index: number): void {
    const selectedTab = this.tabs[index];
    if (selectedTab) {
      this.tabSelected.emit(selectedTab.key);
    }
  }

  onRowSelection(row: any): void {
    this.rowSelected.emit(row);
    if (row && row.paymentSlipUrl) {
      this.isDocumentLoading = true;
      setTimeout(() => {
        if (this.isDocumentLoading) {
          this.isDocumentLoading = false;
        }
      }, 500);
    } else {
      this.isDocumentLoading = false;
    }
  }

  onDocumentLoad(): void {
    this.isDocumentLoading = false;
  }

  onDocumentError(): void {
    this.isDocumentLoading = false;
  }
}
