import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-modal-deleted',
  templateUrl: './modal-deleted.component.html',
  styleUrl: './modal-deleted.component.scss',
})
export class ModalDeletedComponent {
  @Input() visible: boolean = false;
  @Input() id: any | null = null;
  @Input() buttonAction: (() => void) | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  onButtonConfirmedClick() {
    if (this.buttonAction) {
      this.buttonAction();
    }
    this.closeDialog();
  }

  onButtonCancelClick() {
    this.closeDialog();
  }
}
