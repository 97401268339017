import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CurrencyService } from '../../../services/currency.service';
import { CompanyService } from '../../../services/company.service';
import { EmployeeService } from '../../../services/employee.service';
// import { PaymentSlipService } from '../../services/payment-slip.service';
import { forkJoin } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { SolicitudeService } from '../../services/solicitude.service';

@Component({
  selector: 'app-detail-solicitude-vacation',
  templateUrl: './detail-solicitude-vacation.component.html',
  styleUrls: ['./detail-solicitude-vacation.component.scss'],
  providers: [DialogService],
})
export class DetailSolicitudeVacationComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() dataToView: any | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();

  steps = [
    { label: 'Recibido', key: 1 },
    { label: 'En evaluación', key: 2 },
    { label: 'Aprobado', key: 3 },
    { label: '', key: 4 },
  ];

  get visibleSteps() {
    if (this.status === 'aprobado') {
      return this.steps.slice(0, 4); // Muestra solo los primeros 3 labels
    }
    if (this.status === 'rechazado') {
      return this.steps.slice(0, 4);
      // return [
      //   this.steps[0], // Recibido
      //   this.steps[1], // En evaluación
      //   { label: 'Rechazado', key: 4 }, // Rechazado
      // ];
    }
    return this.steps;
  }

  currentStep = 1;
  status: 'pendiente' | 'aprobado' | 'rechazado' = 'pendiente';

  solicitude = {
    stateSolicitudeId: 1,
    stateSolicitudeName: 'En evaluación',
  };

  // Método para actualizar el estado actual basado en la solicitud

  constructor(
    private solicitudeService: SolicitudeService,
    private currencyService: CurrencyService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private cdr: ChangeDetectorRef
  ) {}

  isLoading: boolean = false;
  dateStart: Date = new Date();
  dateEnd: Date = new Date();
  message: string = '';
  adminMessage: string = '';
  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  ngOnChanges(): void {
    console.log('en el onchanges del detail');
    this.isLoading = true;

    if (this.visible && this.dataToView) {
      this.solicitudeService
      .getDetailSolicitudeByDetailSolicitudeId(
        this.dataToView.detailSolicitudId
      )
      .subscribe({
        next: (solicitude: any) => {
          this.solicitude = {
            stateSolicitudeId: solicitude.stateSolicitudeId,
            stateSolicitudeName: solicitude.stateSolicitudeName,
          };
          this.updateState(solicitude.stateSolicitudeId);
          this.dateStart = solicitude.breakStartDate;
          this.dateEnd = solicitude.breakEndDate;
          this.message = solicitude.detailSolicitudeDescription;
          this.adminMessage = solicitude.detailSolicitudeMessage;

          this.isLoading = false;
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.isLoading = false;
          console.error(
            'Error al obtener los detalles del recibo de pago:',
            err
          );
        },
      });
    }
    
  }


  updateState(stateId: number): void {
    switch (stateId) {
      case 1: // Recibido
        this.currentStep = 1;
        this.status = 'pendiente';
        // this.primaryButtonLabel = 'EVALUAR';
        // this.showButtons = true;
        // this.showConfirmation = true;
        // this.showTextarea = true;
        break;
      case 2: // En evaluación
        this.currentStep = 2;
        this.status = 'pendiente';
        // this.primaryButtonLabel = 'APROBAR';
        // this.showButtons = true;
        // this.showConfirmation = true;
        // this.showTextarea = true;
        break;
      case 3: // Aprobado
      case 4: // Rechazado
        this.currentStep = stateId === 3 ? 4 : 3;
        this.status = stateId === 3 ? 'aprobado' : 'rechazado';
        // this.showButtons = false;
        // this.showConfirmation = false;
        // this.showTextarea = false;
        break;
      default:
        console.warn('Estado no manejado:', stateId);
    }
  }

  parseCustomDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    const fullYear = year < 100 ? 2000 + year : year;
    return new Date(fullYear, month - 1, day);
  }

  onButtonClick() {
    this.closeDialog();
  }
}
