<div class="boletas-container">
  <!-- Título y subtítulo -->
  <app-page-header
    [title]="'Boletas'"
    [subtitle]="'Administra tus boletas y facturas'"
  ></app-page-header>

  <!-- Botones, combo y barra de búsqueda -->
  <app-page-acciones
    [comboOptions]="monthOptions"
    [comboSelected]="currentMonth"
    (comboSelectedChange)="onMonthChange($event)"
    [searchQuery]="searchTerm"
    (searchQueryChange)="onSearch($event)"
  >
  </app-page-acciones>

  <!-- Tabs y tabla dinámica -->
  <app-page-container
    [tabs]="tabs"
    [displayedColumns]="displayedColumns"
    [dataSource]="dataSource"
    [actionButtons]="actionButtons"
    [showPreviewCard]="true"
    [isLoading]="isLoading"
    [customTemplates]="{
      paymentSlipAmount: paymentSlipAmountTemplate,
      employeeName: employeeNameTemplate
    }"
    (tabSelected)="onTabSelected($event)"
    (rowSelected)="onRowSelected($event)"
    [selectedDocument]="selectedDocument"
    [hasCheckbox]="true"
  >
    <ng-template #paymentSlipAmountTemplate let-row>
      {{ row.paymentSlipAmount | currencySymbol : row.currencyId }}
    </ng-template>
    <ng-template #employeeNameTemplate let-row>
      {{ row.employeeName | capitalize }}
    </ng-template>
  </app-page-container>

  <!-- Modal del detalle -->
  <app-detail-modal
  [(visible)]="mostrarModalDetalle"
  [dataToView]="dataDetail"
  (visibleChange)="onDialogDetailClose()"
></app-detail-modal>
</div>
