import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SolicitudeRoutingModule } from './solicitude-routing.module';
import { SolicitudeComponent } from './solicitude.component';
import { StepsModule } from 'primeng/steps';
import { DetailSolicitudeLoanComponent } from './components/detail-solicitude-loan/detail-solicitude-loan.component';
import { DetailSolicitudeVacationComponent } from './components/detail-solicitude-vacation/detail-solicitude-vacation.component';

@NgModule({
  declarations: [SolicitudeComponent,
    DetailSolicitudeLoanComponent,
    DetailSolicitudeVacationComponent,
  ],
  imports: [
    SolicitudeRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    CalendarModule,
    InputSwitchModule,
    StepsModule
  ],
  providers: [DialogService],
})
export class SolicitudeModule {}
