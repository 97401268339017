import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PaymentSlipResponseDTO } from '../../interfaces/payment-slips.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentSlipService {
  private apiUrl = `${environment.apiUrl}/paymentSlips`;

  constructor(private http: HttpClient) {}

  getPaymentSlipById(id: number): Observable<any> { 
    return this.http.get<any>(`${this.apiUrl}/detailPaymentSlipById/${id}`);
  }  
  postPaymentSlipById(id: number): Observable<any> { 
    return this.http.post<any>(`${this.apiUrl}/detailPaymentSlip/${id}`, null);
  }  

  getAllPaymentSlips(filter: { search: string }): Observable<PaymentSlipResponseDTO[]> {
    return this.http.post<PaymentSlipResponseDTO[]>(this.apiUrl + '/listPaymentSlipByEmployee', filter);
  }

}
