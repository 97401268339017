import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { VacationComponent } from './vacation.component';
import { VacationRoutingModule } from './vacation-routing.module';
import { ButtonModule } from 'primeng/button';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';

@NgModule({
  declarations: [VacationComponent,
    ModalInfoComponent
  ],
  imports: [
    CommonModule,
    // BrowserModule,
    VacationRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    MatPseudoCheckboxModule,
    AutoCompleteModule,
    ButtonModule,
    InputSwitchModule,
    CheckboxModule
  ],
  providers: [DialogService],
})
export class VacationModule {}
