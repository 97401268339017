import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  userData: any = null;
  employeeData: any;
  selectedDate: Date | null = null;

  coupons = [
    {
      title: 'Descanso por maternidad',
      expiration: new Date('2024-07-26T00:00:00'),
    },
    {
      title: 'Descanso por maternidad',
      expiration: new Date('2024-07-26T00:00:00'),
    },
    {
      title: 'Chancho al palo con papas',
      expiration: new Date('2024-07-26T00:00:00'),
    },
  ];

  isLast(coupon: any): boolean {
    return this.coupons.indexOf(coupon) === this.coupons.length - 1;
  }

  constructor(private employeeService: EmployeeService) {}

  ngOnInit(): void {
    this.employeeService.employeeData$.subscribe((data) => {
      this.employeeData = data;
    });

    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
  }

  getCompanyLogo(companyId: number): string {
    switch (companyId) {
      case 1:
        return 'keola.svg';
      case 2:
        return 'inclub.svg';
      case 3:
        return 'inresort.svg';
      // Agregar los demás logos de las empresas
      default:
        return 'default-logo.svg';
    }
  }
  
}
