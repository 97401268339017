import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(amount: string, currencyId: number): string {
    if (!amount) return '-'; // Manejo de valores nulos o indefinidos
    const symbol = currencyId === 1 ? 'S/.' : currencyId === 2 ? '$' : '';
    return `${symbol} ${Number(amount).toFixed(2)}`;
  }
}
