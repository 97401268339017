<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Cards Layout</title>
  </head>
  <body>
    <div class="container">
      <!-- Banner -->
      <div class="card-banner">
        <div class="overlay">
          <img
            [src]="'/assets/svg/dashboard/' + getCompanyLogo(userData.companyId)"
            alt="Company Logo"
            class="company-logo"
          />
          <img src="/assets/svg/dashboard/bienvenido.svg" alt="Bienvenido Colaborador" class="welcome-text" />
        </div>
        <img src="/assets/svg/dashboard/banner.svg" alt="Banner" />
      </div>

      <!-- Segunda fila -->
      <div class="row">
        <div class="card wide-card">
          <div class="avatar-section">
            <img
              src="./assets/images/image-profile.png"
              alt="profile"
              class="avatar"
            />
            <button
              pButton
              type="button"
              icon="pi pi-pencil"
              class="p-button-rounded edit-button"
            ></button>
          </div>
          <div class="info-section">
            <h2 class="name">
              {{ employeeData?.names | capitalize }}
              {{ employeeData?.lastNames | capitalize }}
            </h2>
            <div class="detail">
              <span class="label">Rol:</span>
              <span class="value">{{
                employeeData?.jobPositionName || "Administrador" | capitalize
              }}</span>
            </div>
            <div class="detail">
              <span class="label">Email:</span>
              <span class="value">{{ employeeData?.email }}</span>
            </div>
            <div class="detail">
              <span class="label">Número:</span>
              <span class="value">{{ employeeData?.phone }}</span>
            </div>
            <div class="detail">
              <span class="label">Empresa:</span>
              <span class="value">{{ employeeData?.companyName }}</span>
            </div>
          </div>
        </div>

        <div class="card small-card">
          <!-- <p>asddas</p> -->
          <div class="calendar-container">
            <p-calendar
              [(ngModel)]="selectedDate"
              [readonlyInput]="true"
              inline="true"
            />
          </div>
        </div>
        <div class="card small-card">
          <div class="card-header">
            <h2>Cupones y vales <span class="badge">2</span></h2>
            <a href="#" class="view-all">Ver todo</a>
          </div>
          <div class="card-content">
            <div *ngFor="let coupon of coupons; let i = index">
              <div class="coupon">
                <div class="details">
                  <p>{{ coupon.title }}</p>
                  <span>Vence {{ coupon.expiration | date: 'dd/MM/yy' }}</span>
                </div>
                <a href="#" class="view-link">Ver</a>
              </div>
              <hr *ngIf="i < coupons.length - 1" />
            </div>
          </div>          
        </div>        
      </div>

      <!-- Tercera fila -->
      <div class="row">
        <div class="card half-card">
          <h2 class="section-title">Info. Básica</h2>
          <div class="info-basic">
            <div class="info-item">
              <span class="label">Inicio</span>
              <span class="value">{{ employeeData?.startDate }}</span>
            </div>
            <div class="info-item">
              <span class="label">Hasta</span>
              <span class="value">{{ employeeData?.endDate }}</span>
            </div>
            <div class="info-item">
              <span class="label">Modalidad</span>
              <span class="value">{{
                employeeData?.workingModeName | capitalize
              }}</span>
            </div>
            <div class="info-item">
              <span class="label">Planilla</span>
              <span class="value">{{
                employeeData?.payRoll == true
                  ? "Estás en Planilla"
                  : "Estás en RxH"
              }}</span>
            </div>
          </div>

          <hr />
          <h2 class="section-title">Info. Personal</h2>
          <div class="info-personal">
            <div class="info-row">
              <span class="label">Nacimiento</span>
              <span class="value"
                ><i class="pi pi-calendar"></i>
                {{ employeeData?.birthDate }}</span
              >
            </div>
            <div class="info-row">
              <span class="label">Dirección</span>
              <span class="value"
                ><i class="pi pi-map-marker"></i>
                {{ employeeData?.address | capitalize }}</span
              >
            </div>
            <div class="info-row">
              <span class="label">Correo</span>
              <span class="value">{{ employeeData?.email }}</span>
            </div>
          </div>

          <hr />
          <h2 class="section-title">Ocupación</h2>
          <div class="occupation">
            <div class="occupation-item">
              <i class="pi pi-clock"></i>
              <div class="occupation-item-bottom">
                <span class="value">Tiempo Completo</span>
                <span class="subvalue">9am-7pm</span>
              </div>
            </div>
            <div class="occupation-item">
              <i class="pi pi-microsoft"></i>
              <div class="occupation-item-bottom">
                <span class="value"
                  >Área de {{ employeeData?.areaName | capitalize }}</span
                >
                <span class="subvalue">{{ employeeData?.companyName }}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div class="card half-card"></div>
      </div>
    </div>
  </body>
</html>
