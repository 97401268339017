import { IComboBase } from "../interfaces/base/base.interface";

export const monthOptions: IComboBase[] = [
  { key: '01', text: 'Enero' },
  { key: '02', text: 'Febrero' },
  { key: '03', text: 'Marzo' },
  { key: '04', text: 'Abril' },
  { key: '05', text: 'Mayo' },
  { key: '06', text: 'Junio' },
  { key: '07', text: 'Julio' },
  { key: '08', text: 'Agosto' },
  { key: '09', text: 'Septiembre' },
  { key: '10', text: 'Octubre' },
  { key: '11', text: 'Noviembre' },
  { key: '12', text: 'Diciembre' },
];
